<template>
  <div class="height-100 max_box">
    <!-- <div class="play_layer" id="playLayer" @click="startPlay">
      <span class="play_btn"> </span>
    </div> -->
    <div class="audio-box">
      <audio
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-e2cb1aa8.mp3"
        loop="loop"
        ref="bgAudio"
      ></audio>
      <audio
        @canplay="getDuration(1)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-4d9c6690.mp3"
        ref="audio1"
      ></audio>
      <audio
        @canplay="getDuration(2)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-97b2c51a.mp3"
        ref="audio2"
      ></audio>
      <audio
        @canplay="getDuration(3)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-a69e5e67.mp3"
        ref="audio3"
      ></audio>
      <audio
        @canplay="getDuration(4)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-35f14b39.mp3"
        ref="audio4"
      ></audio>
      <audio
        @canplay="getDuration(5)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-552d844f.mp3"
        ref="audio5"
      ></audio>
      <audio
        @canplay="getDuration(6)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-bd1c0d77.mp3"
        ref="audio6"
      ></audio>
      <audio
        @canplay="getDuration(7)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-50661c1a.mp3"
        ref="audio7"
      ></audio>
      <audio
        @canplay="getDuration(8)"
        src="https://video-config.oss-cn-hangzhou.aliyuncs.com/videocon-93bf385a.mp3"
        ref="audio8"
      ></audio>
    </div>

    <!-- pop_box -->
    <div :class="{ pop_box: true, isIframeVisible: isIframeVisible }">
      <i class="pop_close" @click.stop="popClose"></i>
      <div
        class="iframe_layer"
        @click.stop="popClose"
        v-show="isIframeVisible"
      ></div>
      <iframe
        :src="iframeUrl"
        frameborder="0"
        v-show="isIframeVisible"
      ></iframe>
    </div>

    <div class="animate__animated animate__fadeIn duration" id="card1">
      <div class="title">
        <div class="img_box">
          <img src="../../assets/img/img1.png" alt="" />
        </div>
        <div class="title_name">华林证券周周汇集合资产管理计划</div>
        <div class="title_time">产品代码：E50473</div>
        <div class="btn_start" id="btnStart" @click="startPlay">
          {{ isFullLoaded ? "直接播放" : "加载中..." }}
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/hualinlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        产品代码：E50473
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">产品介绍</div>
      </div>
      <div class="content_box">
        <div class="box">
          <div class="box_title">净值</div>
          <p>单位净值（2021-10-22）：1.0232</p>
          <p>累计净值（2021-10-22）：1.0232</p>
        </div>
        <div class="box">
          <div class="box_title bg_brown">最近开放日</div>
          <p class="bg_br">2021年10月26日（申赎）</p>
        </div>
        <div class="box">
          <p class="box_content">产品名称：周周汇</p>
          <p class="box_content">产品代码：E50473</p>
          <div class="tips">
            <div>
              <img src="../../assets/img/smile.png" alt="" />
            </div>
            <p>温馨提示：产品有风险，投资需谨慎</p>
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/hualinlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        产品代码：E50473
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">产品介绍</div>
      </div>
      <div class="content_box">
        <div class="title_card">产品亮点</div>
        <ul>
          <li>
            <p>
              <img src="../../assets/img/dot.png" alt="" /><span
                >收益稳健，严控回撤</span
              >
            </p>
            <p>
              投资策略上充分发挥管理人在资产配置方面的
              特长，根据不同的市场行情调整债券投资策略，
              以期能为您创造长期稳健的投资回报；
            </p>
          </li>
          <li>
            <p>
              <img src="../../assets/img/dot.png" alt="" /><span
                >流动性好，灵活救赎</span
              >
            </p>
            <p>
              每周二自由申赎，无锁定期，满足投资者定期、
              灵活、持续的投资需求，且无需参与赎回费，降 低您的投资成本；
            </p>
          </li>
          <li>
            <p>
              <img src="../../assets/img/dot.png" alt="" /><span
                >资产安全，风险可控</span
              >
            </p>
            <p>
              投资配置以中高评级债券配置为主，把控收益
              与风险的整体风险偏好，降低您的投资风险
            </p>
          </li>
        </ul>

        <!-- <div class="tips_holder">
          <div
            class="tips"
            @click="showIframe('https://baike.baidu.com/item/FLOPS/989494')"
          >
            “EFLOPS”百科注解
          </div>
         
        </div> -->
      </div>
      <div class="logo-box">
        <img src="../../assets/img/hualinlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        产品代码：E50473
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">产品介绍</div>
      </div>
      <div class="content_box">
        <div class="title_card">产品成立以来投资业绩</div>
        <div class="title_card">（近7日年化收益率：4.59%）</div>
        <div class="chart1_dec">
          本产品自成立以来，净值增长稳定，在市场上大幅波动时依旧保持良好增长态势。
        </div>
        <div class="chart_box">
          <div class="chart_name">周周汇成立以来净值增长情况</div>
          <div id="chart1"></div>
          <div class="line">
            <span></span> <span style="margin-right: 20px">周周汇</span>
            <span></span><span>比较基准</span>
          </div>
          <div class="info">
            <p>备注：比较基准为中债-综合全价（1年以下）指数</p>
            <p>数据来源：wind、华林证券 截止时间：2021-10-22</p>
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/hualinlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="top_title">
        <span style="margin-right: 5px"></span>
        <span></span>
        产品代码：E50473
        <span style="margin-right: 5px"></span>
        <span></span>
        <div class="time">投资团队</div>
      </div>
      <div class="content_box">
        <ul>
          <li>
            <div class="top">
              <div class="per_box">
                <img src="../../assets/img/consult.png" alt="" />
              </div>

              <div class="intro">
                <p>伦健生先生</p>
                <p>资产管理总经理\资深投资经理</p>
                <p>13余年金融从业经验</p>
              </div>
            </div>
            <div>
              对宏观经理、债券投资、组合管理具有深刻的认
              知，投资风格稳健，历史业绩优秀。
            </div>
          </li>
          <li>
            <div class="top">
              <div class="per_box">
                <img src="../../assets/img/consult.png" alt="" />
              </div>

              <div class="intro">
                <p>王文静女士</p>
                <p>高级投资经理</p>
                <p>10余年金融从业经验</p>
              </div>
            </div>
            <div>
              从事宏观量化及模型搭建、大类资产配置研究，
              FRM，南京大学金融工程硕士
            </div>
          </li>
          <li>
            <div class="top">
              <div class="per_box">
                <img src="../../assets/img/consult.png" alt="" />
              </div>

              <div class="intro">
                <p>何展鹏先生</p>
                <p>高级投资经理</p>
                <p>9年金融从业经验</p>
              </div>
            </div>
            <div>
              从事量化分析研究工作，对投资组合管理有深
              入研究，加拿大布鲁克大学金融学硕士
            </div>
          </li>
        </ul>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/hualinlogo.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="content" v-show="contentShow">
        <div class="top_title">
          <span style="margin-right: 5px"></span>
          <span></span>
          产品代码：E50473
          <span style="margin-right: 5px"></span>
          <span></span>
          <div class="time">产品介绍</div>
        </div>
        <div class="content_box">
          <div class="risk">
            <div class="img_box">
              <img src="../../assets/img/shuline.png" alt="" />
            </div>
            <span>产品名称：华林证券周周汇集合资产管理计划</span>
          </div>
          <div class="risk">
            <div class="img_box">
              <img src="../../assets/img/shuline.png" alt="" />
            </div>
            <span>管理人：华林证券股份有限公司</span>
          </div>
          <div class="risk">
            <div class="img_box">
              <img src="../../assets/img/shuline.png" alt="" />
            </div>
            <span>托管人：宁波银行股份有限公司</span>
          </div>
          <div class="risk">
            <div class="img_box">
              <img src="../../assets/img/shuline.png" alt="" />
            </div>
            <span>投资范围：投资债券及其他固定收益类资产</span>
          </div>
          <div class="risk">
            <div class="img_box">
              <img src="../../assets/img/shuline.png" alt="" />
            </div>
            <span>开放日期：每周二开放申购赎回</span>
          </div>
          <div class="risk">
            <div class="img_box">
              <img src="../../assets/img/shuline.png" alt="" />
            </div>
            <span>参与退出：参与起点30万，追回1000元的整</span>
          </div>
          <div class="text-style">倍数增加；注：个人持有净值低</div>
          <div class="text-style" style="margin-right: 38px">
            于30万时将触发全部赎回
          </div>
          <div class="tips">
            <div>
              <img src="../../assets/img/smile.png" alt="" />
            </div>
            <p>温馨提示：产品有风险，投资需谨慎</p>
          </div>
        </div>
        <div class="ad" @click="goAd">
          <img src="../../assets/img/ad_pic.png" alt="" />
        </div>
        <div class="logo-box">
          <img src="../../assets/img/hualinlogo.png" alt="" />
        </div>
      </div>
      <div class="ad_page" v-show="adShow">
        <div class="img_box" @click="goBack">
          <img src="../../assets/img/ad_pg.jpg" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="content" v-show="contentShow">
        <div class="top_title">
          <span style="margin-right: 5px"></span>
          <span></span>
          产品代码：E50473
          <span style="margin-right: 5px"></span>
          <span></span>
          <div class="time">产品介绍</div>
        </div>
        <div class="content_box">
          <div class="risk">
            <div class="img_box">
              <img src="../../assets/img/shuline.png" alt="" />
            </div>
            <span>产品风险等级：中低风险</span>
          </div>
          <div class="box">
            <div class="box_title">对象</div>
            <p>适合风险承受能力为</p>
            <p class="red_text">谨慎型、稳健型、积极型、激进型</p>
            <p>的合格投资者</p>
          </div>
          <div class="box">
            <div class="box_title bg_blue">费率</div>
            <p>
              <span class="mr-45"
                >申购费：<span class="blue_text">免收</span></span
              >
              <span>退出费：<span class="blue_text">免收</span></span>
            </p>
            <p>管理费：0.25%/年</p>
            <p>托管费：0.01%/年</p>
            <p style="font-size: 13px">业绩提成：年化收益率3.8%以上部分的50%</p>
          </div>
          <div class="tips">
            <div>
              <img src="../../assets/img/smile.png" alt="" />
            </div>
            <p>温馨提示：产品有风险，投资需谨慎</p>
          </div>
          <div class="ad" @click="goAd2">
            <img src="../../assets/img/ad_pic.png" alt="" />
          </div>
        </div>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/hualinlogo.png" alt="" />
      </div>
      <div class="ad_page" v-show="adShow">
        <div class="img_box" @click="goBack2">
          <img src="../../assets/img/ad_pg.jpg" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="content_box">
        <div class="box_title">风险提示</div>
        <p>
          产品过往业绩不预示其未来业绩，产品管理人管理的其他产品不构成本基金业绩的保证，投资需谨慎，详阅法律文件。本报告可能包含某些享有专有法律全力或需要保密的信息。本报告仅供内部使用，任何未经华林证券股份有限公司同意或授权而对该报告所载内容进行使用、披露、分发、复制的行为均被严格禁止。任何情况下，对于使用该报告所包含的信息所引起的损失、损害等后果，华林证券股份有限公司概不承担任何责任。本报告引用的财务数据未经托管行复核；所引用的观点、分析及预测仅代表投资经理在目前特定市场情况下并基于一定的假设条件下的分析和判断，并不意味着适合今后所有的市场状况，也不构成对阅读者的投资建议。投资有风险，本公司或本公司相关机构、雇员或代理人不对任何人使用此全部或部分内容的行为或由此而引致的任何
          损失承担任何责任。
        </p>
      </div>
      <div class="logo-box">
        <img src="../../assets/img/hualinlogo.png" alt="" />
      </div>
      <div class="restart" v-show="reStartState" @click="reStart()">
        再看一遍
      </div>
    </div>

    <div
      :class="{
        pop_news_holder: true,
        animate__animated: true,
        animate__fadeInUp: isNewsVisible,
        isNewsVisible: isNewsVisible,
      }"
    >
      <div class="pop_news_layer" @click="closeNews"></div>
      <div class="pop_news">
        <i class="pop_close" @click="closeNews"></i>
        <div class="n_title">
          未来三年有望高增长的4大数据中心概念股，市场将保持稳步增长
        </div>
        <div class="n_info"><span>银河证券</span><span>2021.10.25</span></div>
        <div class="n_content">
          <p>
            近日工业和信息化部印发《新型数据中心发展三年行动计划（2021-2023年）》。通知指出，用3年时间，基本形成布局合理、技术先进、绿色低碳、算力规模与数字经济增长相适应的新型数据中心发展格局。
          </p>
          <p>
            与传统数据中心相比，新型数据中心具有高技术、高算力、高能效、高安全等特征，在数字化日益普及的今日，新型数据中心能更好支撑新一代信息技术加速创新，加快推动制造强国和网络强国建设。
          </p>
          <p>
            受新基建、网络强国、数字经济等国家政策影响以及新一代信息技术发展的驱动，我国互联网数据中心（IDC）业务收入保持高速增长，机构预测未来仍将保持快速增长势头，预计2021年IDC行业市场收入可达1282亿元。
          </p>
          <p>
            到2023年底，全国数据中心机架规模年均增速保持在20%左右，平均利用率力争提升到60%以上，总算力超过200
            EFLOPS，高性能算力占比达到10%。国家枢纽节点算力规模占比超过70%。
          </p>
          <p>
            在云计算、下一代无线网络、大数据、物联网、人工智能等新一代信息技术的融合应用与行业数字化转型发展的共同驱动下，中国数据中心市场将保持稳步增长。
          </p>
          <p>未来三年有望高增长的4大数据中心概念股</p>
          <p>
            科华数据：国内UPS 龙头厂商，UPS 业务与IDC 业务协同发展，IDC
            建设运营及其配套高端电源业务提升对大客户的综合服务能力。
          </p>
          <p>
            上半年公司数据中心、新能源及智慧电能业务稳健增长，获得了腾讯新的数据中心运营订单，在“双碳”目标及数字经济背景下，积极探索绿色数据中心的智能电能综合解决方案。
          </p>
          <p>
            在数据中心产品和技术方面具有竞争力，拥有一线城市IDC
            核心资源，同时与腾讯深度合作，业务有较大的成长空间。
          </p>
          <p>
            中科曙光：信创服务器+芯片龙头，在全国建设运营50多个云计算数据中心和大数据中心，服务超过10000家单位。
          </p>
          <p>
            近年来，公司调整战略重心，布局云、高端运算、存储、网全、自主软件等领域，以形成完整安全的技术体系。
          </p>
          <p>
            受益于新基建政策的促进，各地政府相继推进大数据和智慧城市建设，大型数据中心的需求越来越强烈，由此带动服务器产品市场的繁荣。
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="progess">
      <span class="play" v-show="playState"></span>
      <span class="zanting" v-show="!playState"></span>
      <input
        id="range"
        type="range"
        min="1"
        max="9"
        value="1"
        step="1"
        @input="changeRange()"
      />
    </div> -->
  </div>
</template>

<script>
import $ from "jquery";
import { sleep, throttle } from "../../utils/common";
import { drawVolume, drawK } from "@/components/kChartNoBorder.js";
import { ImagePreview } from "vant";
// import VConsole from "vconsole";

// 当在生产环境时，打开调试工具
// if (process.env.NODE_ENV == "production") {
// new VConsole();
// }

export default {
  name: "Template56",
  data() {
    return {
      seccode: "2020882313",
      secname: "",
      dataK: [],
      ma5: "",
      ma10: "",
      ma20: "",
      startDate: "",
      endDate: "",
      isIframeVisible: false,
      iframeUrl: "",
      isNewsVisible: false,
      currentCard: 1,
      lastTimestamp: 0, //记录进入audio播放的时间戳
      isFullLoaded: true, //全部需要预先加载的完成标志
      previewImages: [
        require("../../assets/img/stock_img1.png"),
        require("../../assets/img/fund_img1.png"),
      ],
      reStartState: false,
      playState: true,
      adShow: false,
      contentShow: true,
    };
  },
  mounted() {
    document.title = "华林证券周周汇资产管理计划";
    // $('#card1').addClass('none')
    // this.playCard4()
  },
  methods: {
    //加载完毕后才可以播放
    getDuration(count) {
      // if (count >= 6) {
      this.isFullLoaded = true;
      // }
    },
    jumpUrl(url) {
      window.open(url);
    },
    showImage(imageIndex) {
      const _this = this;
      this.$refs["audio" + this.currentCard].pause();
      ImagePreview({
        images: [_this.previewImages[imageIndex]],
        showIndex: false,
        onClose() {
          _this.$refs["audio" + _this.currentCard].play();
        },
      });
    },
    showNews(newsIndex) {
      const _this = this;
      this.$refs["audio" + this.currentCard].pause();
      this.isNewsVisible = true;
    },
    closeNews() {
      this.isNewsVisible = false;
      this.$refs["audio" + this.currentCard].play();
    },
    showIframe(url) {
      this.isIframeVisible = true;
      this.iframeUrl = url;
      this.$refs["audio" + this.currentCard].pause();
    },
    popClose() {
      this.isIframeVisible = false;
      this.iframeUrl = "";
      this.$refs["audio" + this.currentCard].play();
    },
    loadChart1() {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      myChart.setOption({
        xAxis: {
          type: "category",
          show: true,
          data: [
            "2021.05.25",
            "2021.05.26",
            "2021.05.27",
            "2021.05.28",
            "2021.05.29",
            "2021.08.08",
            "2021.10.22",
          ],
          axisLabel: {
            align: "center",
            textStyle: {
              fontSize: 8,
              color: "#E0AD94",
            },
          },
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#E0AD94", //左边线的颜色
              width: "1", //坐标线的宽度
            },
          },
          axisTick: {
            show: false, //y轴坐标点消失
          },
        },
        grid: {
          top: 20,
          left: 30,
          right: 20,
          bottom: 25,
          show: true,
          borderWidth: "0.1",
          color: ["#FFD2AC"],
        },

        yAxis: {
          type: "value",
          max: 1.025,
          min: 0.99,
          axisLabel: {
            textStyle: {
              fontSize: 8,
              color: "#E0AD94",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#E0AD94"],
            },
          },
        },
        series: [
          {
            data: [0.992, 0.994, 0.9965, 0.9968, 0.998, 0.999, 0.9992],
            type: "line",
            symbol: "none",
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#3173EF",
                },
              },
            },
          },
          {
            data: [0.999, 1.0002, 1.004, 1.008, 1.01, 1.02, 1.024],
            type: "line",
            symbol: "none",
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#FF4A40",
                },
              },
            },
          },
        ],
      });
    },
    goAd() {
      this.adShow = true;
      this.contentShow = false;
      this.$refs.audio6.pause();
    },
    goBack() {
      this.adShow = false;
      this.contentShow = true;
      this.$refs.audio6.play();
    },
    goAd2() {
      this.adShow = true;
      this.contentShow = false;
      this.$refs.audio7.pause();
    },
    goBack2() {
      this.adShow = false;
      this.contentShow = true;
      this.$refs.audio7.play();
    },

    moniteAudioEnd() {
      return new Promise((resolve, reject) => {
        const moniteHandler = setInterval(async () => {
          if (
            this.$refs["audio" + this.currentCard].currentTime >=
            this.$refs["audio" + this.currentCard].duration - 0.3
          ) {
            await sleep(400);
            clearInterval(moniteHandler);
            resolve();
          }
        }, 100);
      });
    },
    playCard1(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 1;
        this.$refs.audio1.play();
        $("#card1").removeClass("none");
        $("#btnStart").addClass("none");
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        $("#card1").addClass("none");
        resolve();
      });
    },
    playCard2(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 2;
        this.$refs.audio2.play();
        $("#card2").removeClass("none");
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        $("#card2").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card2").addClass("none");
        resolve();
      });
    },
    playCard3(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 3;
        this.$refs.audio3.play();
        this.lastTimestamp = this.$refs.audio3.currentTime;
        $("#card3").removeClass("none");
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        await $("#card3").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card3").addClass("none");
        resolve();
      });
    },
    playCard4(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 4;
        this.$refs.audio4.play();
        $("#card4").removeClass("none");
        this.loadChart1();
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        $("#card4").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card4").addClass("none");
        resolve();
      });
    },
    playCard5(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 5;
        this.$refs.audio5.play();
        $("#card5").removeClass("none");
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        $("#card5").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card5").addClass("none");
        resolve();
      });
    },
    playCard6(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 6;
        this.$refs.audio6.play();
        $("#card6").removeClass("none");
        setTimeout(() => {
          $("#ad1").removeClass("none");
        }, 1000);
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        $("#card6").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card6").addClass("none");
        resolve();
      });
    },
    playCard7(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 7;
        this.$refs.audio7.play();
        $("#card7").removeClass("none");
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        $("#card7").addClass("animate__fadeOut");
        await sleep(0.6 * 1000);
        $("#card7").addClass("none");
        resolve();
      });
    },
    playCard8(isDebugStop) {
      return new Promise(async (resolve, reject) => {
        this.currentCard = 8;
        this.$refs.audio8.play();
        $("#card8").removeClass("none");
        await this.moniteAudioEnd();
        if (isDebugStop) {
          return;
        }
        // await sleep(1 * 1000);
        // $("#card8").addClass("animate__fadeOut");
        // await sleep(0.6 * 1000);
        // $("#card8").addClass("none");
        resolve();
      });
    },
    async playBgAudio() {
      this.$refs.bgAudio.play(); //背景音乐播放
      await sleep(500);
      this.$refs.bgAudio.volume = 0.1; //背景音乐音量调节（1为最大）
    },
    //开始播放
    async startPlay() {
      if (!this.isFullLoaded) {
        return;
      }
      // $("#playLayer").addClass("none");
      this.playBgAudio();
      await this.playCard1();
      await this.playCard2();
      await this.playCard3();
      await this.playCard4();
      await this.playCard5();
      await this.playCard6();
      await this.playCard7();
      await this.playCard8();
      this.reStartState = true;
    },
    // 重播
    async reStart() {
      $("#card2").removeClass("animate__fadeOut");
      $("#card3").removeClass("animate__fadeOut");
      $("#card4").removeClass("animate__fadeOut");
      $("#card5").removeClass("animate__fadeOut");
      $("#card6").removeClass("animate__fadeOut");
      $("#card7").removeClass("animate__fadeOut");
      $("#card8").removeClass("animate__fadeOut");
      this.reStartState = false;
      this.currentCard = 1;
      this.startPlay();
    },
    // rang进度条change
    changeRange() {
      const range = document.getElementById("range");
      if (range.value == 1) {
        this.$refs["audio" + this.currentCard].pause();
        this.currentCard = 1;
        this.startPlay();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./Template56.less";
</style>
